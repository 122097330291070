import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["recaptcha", "globalbanner", "navbar", "unsavedchanges"];

  connect() {
    this.handleRecaptchaLoadEvent()
    this.fullScreenMobileAndTabletNav();
    this.handleGlobalBanner()
    this.initUnsavedChanges()
  }

  disconnect() {
    this.handleRecaptchaReset()
    this.resetUnsavedChanges()
  }

  initialize() {
    this.cookies = this.parseCookies()
  }

  // Insecure Cookie Store Section ---->

  parseCookies () {
    return document.cookie.split("; ").reduce((acc, cookie) => {
      const [key, value] = cookie.split("=");
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {});
  }

  handleGlobalBanner () {
    if (this.showGlobalBanner() && this.hasGlobalbannerTarget) {
      this.globalbannerTarget.style.display = 'block';
    }
  }

  dismissGlobalBanner () {
    try {
      let insecureCookieStoreValues = this.cookies.insecure_cookie_store;
      if (insecureCookieStoreValues) {
        let data = JSON.parse(insecureCookieStoreValues);
        data.show_global_banner = false;
  
        let domain =  window.deployment_config.shared_cookie_domain;
        let serializedData = encodeURIComponent(JSON.stringify(data));
        document.cookie = `insecure_cookie_store=${serializedData};path=/;domain=${domain}`;

        this.globalbannerTarget.style.display = 'none';
        }
    } catch (e) {
      console.error("Error updating cookie:", e);
    }
  }

  showGlobalBanner () {
    if (this.cookies.insecure_cookie_store) {
        const data = JSON.parse(decodeURIComponent(this.cookies.insecure_cookie_store));
        return data.show_global_banner;
    }
  }

  // <---- Insecure Cookie Store Section

  // Navigation Section ---->

  fullScreenMobileAndTabletNav = () => {
    if (this.hasNavbarTarget) {
      const navbarToggleButton = document.getElementById('navbar-toggle');
  
      if (!navbarToggleButton.dataset.listenerAdded) {
        navbarToggleButton.addEventListener('click', () => {
          this.navbarTarget.classList.toggle('fixed-header');
        });
        navbarToggleButton.dataset.listenerAdded = 'true';
      }
    }
  }

  closeResponsiveLoggedInNavigation = () => {
    history.back();
  }

  // <---- Navigation Section

  // Recaptcha Section ---->

  handleRecaptchaReset () {
    if (typeof grecaptcha !== 'undefined' && grecaptcha && this.recaptchaId !== undefined) {
      grecaptcha.reset(this.recaptchaId);
    }
  }

  handleRecaptchaLoadEvent () {
    if (this.hasRecaptchaTarget) {
      document.addEventListener('recaptcha:loaded', this.initializeRecaptcha.bind(this), { once: true });

      if (window.grecaptcha && window.grecaptcha.render) {
        this.initializeRecaptcha();
      } else {
        console.log("Waiting for reCAPTCHA to load");
      }
    }
  }

  initializeRecaptcha() {
    if (this.recaptchaTarget && this.recaptchaTarget.innerHTML.trim() !== "") {
      console.log("reCAPTCHA is already rendered, skipping initialization.");
      return;
    }

    try {
    this.recaptchaId = grecaptcha.render(this.recaptchaTarget, {
      'sitekey': this.recaptchaTarget.getAttribute("data-recaptcha-sitekey")
    });
    } catch (error) {
      console.error('Failed to render reCAPTCHA', error);
    }
  }

  // <---- Recaptcha Section

  // Unsaved Changes Section
  initUnsavedChanges() {
    this.isChanged = false;
    this.element.addEventListener("change", this.setChanged.bind(this));
    this.element.addEventListener("submit", this.removeChanged.bind(this));
    window.addEventListener("beforeunload", this.handleBeforeUnload.bind(this));

    document.addEventListener("turbo:before-visit", this.handleBeforeFetchRequest.bind(this));
    document.addEventListener("turbo:load", this.handleTurboLoadRequest.bind(this));
  }

  resetUnsavedChanges() {
    this.element.removeEventListener("change", this.setChanged.bind(this));
    window.removeEventListener("beforeunload", this.handleBeforeUnload.bind(this));

    document.removeEventListener("turbo:before-visit", this.handleBeforeFetchRequest.bind(this));
    document.removeEventListener("turbo:load", this.handleTurboLoadRequest.bind(this));
  }

  setChanged() {
      this.isChanged = true;
  }

  removeChanged() {
      this.isChanged = false;
  }

  handleBeforeUnload(event) {
      if (this.isChanged) {
          const message = "Are you sure you want to leave without saving your changes?";
          // need both below for browsers
          event.returnValue = message;
          return message;
      }
  }

  handleTurboLoadRequest(event) {
    this.isChanged = false;
  }

  handleBeforeFetchRequest(event) {
    if (this.isChanged) {
        const shouldProceed = confirm("Are you sure you want to leave without saving your changes?");
        if (!shouldProceed) {
            event.preventDefault();
        } else {
            this.isChanged = false;
        }
    }
  }
  // <---- Unsaved Changes Section

}
